<template>
  <div>
    <ul class="menu_config_box">
      <li class="menu_box">
        <div class="sub_menu_box">
          <span class="sub_title">一级菜单</span>
          <div class="right_btn_box">
            <div class="btn_box" @click="openAddCatalogue(1)">添加目录</div>
            <div class="btn_box2" @click="openAddPage(1)">添加页面</div>
          </div>
        </div>
        <ul class="item_box" v-if="editMenuList.length > 0">
          <draggable v-model="editMenuList" animation="1000">
            <transition-group>
              <li
                :class="[
                'menu_item',
                firstCurrentIndex == index ? 'current_item' : '',
              ]"
                v-for="(item, index) in editMenuList"
                :key="index"
                @click="clickItem(item, index, 1)"
              >
                <div class="left">
                  <i :class="`iconfont lebo-${item.menu_icon}`"></i>
                  <span>{{ item.show_name }}</span>
                </div>
                <div class="right">
                  <i
                    class="iconfont lebo-bianji"
                    style="color: #e39f28"
                    @click.stop.prevent="editItem(item, index, 1)"
                  ></i>
                  <i
                    class="iconfont lebo-shanchu1"
                    style="color: #f55265"
                    @click.stop.prevent="delItem(index, 1)"
                  ></i>
                  <i class="el-icon-arrow-right" v-if="item.is_folder"></i>
                </div>
              </li>
            </transition-group>
          </draggable>
        </ul>
        <div class="empty_box" v-else>
          <img src="@/assets/images/empty.png" alt />
          <span>请添加目录</span>
        </div>
      </li>
      <li
        class="menu_box"
        v-if="editMenuList.length > 0 && editMenuList[firstCurrentIndex].is_folder"
      >
        <div class="sub_menu_box">
          <span class="sub_title">二级菜单</span>
          <div class="right_btn_box">
            <div class="btn_box" @click="openAddCatalogue(2)">添加目录</div>
            <div class="btn_box2" @click="openAddPage(2)">添加页面</div>
          </div>
        </div>
        <ul class="item_box" v-if="editMenuList[firstCurrentIndex].children.length > 0">
          <draggable v-model="editMenuList[firstCurrentIndex].children" animation="1000">
            <transition-group>
              <li
                :class="[
                'menu_item',
                twoCurrentIndex == index ? 'current_item' : '',
              ]"
                v-for="(item, index) in editMenuList[firstCurrentIndex]
.children"
                :key="index"
                @click="clickItem(item, index, 2)"
              >
                <div class="left">
                  <i :class="`iconfont lebo-${item.menu_icon}`"></i>
                  <span>{{ item.show_name }}</span>
                </div>
                <!-- <span v-if="item.show_name">{{ item.show_name }}</span> -->
                <div class="right">
                  <i
                    class="iconfont lebo-bianji"
                    style="color: #e39f28"
                    @click.stop.prevent="editItem(item, index, 2)"
                  ></i>
                  <i
                    class="iconfont lebo-shanchu1"
                    style="color: #f55265"
                    @click.stop.prevent="delItem(index, 2)"
                  ></i>
                  <i class="el-icon-arrow-right" v-if="item.is_folder"></i>
                </div>
              </li>
            </transition-group>
          </draggable>
        </ul>
        <div class="empty_box" v-else>
          <img src="@/assets/images/empty.png" alt />
          <span>请添加目录或页面</span>
        </div>
      </li>
      <li
        class="menu_box"
        v-if="
        editMenuList.length > 0 && editMenuList[firstCurrentIndex].is_folder &&
        editMenuList[firstCurrentIndex].children.length > 0 &&
        editMenuList[firstCurrentIndex].children[twoCurrentIndex].is_folder
      "
      >
        <div class="sub_menu_box">
          <span class="sub_title">三级菜单</span>
          <div class="right_btn_box">
            <!-- <div class="btn_box" @click="openAddCatalogue(3)">添加目录</div> -->
            <div class="btn_box2" @click="openAddPage(3)">添加页面</div>
          </div>
        </div>
        <ul
          class="item_box"
          v-if="
          editMenuList[firstCurrentIndex].children[twoCurrentIndex].children
            .length
        "
        >
          <draggable
            v-model="
            editMenuList[firstCurrentIndex].children[twoCurrentIndex].children
          "
            animation="1000"
          >
            <transition-group>
              <li
                :class="[
                'menu_item',
                threeCurrentIndex == index ? 'current_item' : '',
              ]"
                v-for="(item, index) in editMenuList[firstCurrentIndex]
.children[twoCurrentIndex].children"
                :key="index"
                @click="clickItem(item, index, 3)"
              >
                <div class="left">
                  <i :class="`iconfont lebo-${item.menu_icon}`"></i>
                  <span>{{ item.show_name }}</span>
                </div>
                <div class="right">
                  <i
                    class="iconfont lebo-bianji"
                    style="color: #e39f28"
                    @click.stop.prevent="editItem(item, index, 3)"
                  ></i>
                  <i
                    class="iconfont lebo-shanchu1"
                    style="color: #f55265"
                    @click.stop.prevent="delItem(index, 3)"
                  ></i>
                  <i class="el-icon-arrow-right" v-if="item.is_folder"></i>
                </div>
              </li>
            </transition-group>
          </draggable>
        </ul>
        <div class="empty_box" v-else>
          <img src="@/assets/images/empty.png" alt />
          <span>请添加目录或页面</span>
        </div>
      </li>
      <!-- <li class="menu_box" v-if="
          editMenuList.length > 0 && editMenuList[firstCurrentIndex].is_folder &&
          editMenuList[firstCurrentIndex].children.length > 0 &&
          editMenuList[firstCurrentIndex].children[twoCurrentIndex].children
            .length > 0 &&
          editMenuList[firstCurrentIndex].children[twoCurrentIndex].children[
            threeCurrentIndex
          ].is_folder
        ">
        <div class="sub_menu_box">
          <span class="sub_title">四级菜单</span>
          <div class="right_btn_box">
            <div class="btn_box2" @click="openAddPage(4)">添加页面</div>
          </div>
        </div>
        <ul class="item_box" v-if="
            editMenuList[firstCurrentIndex].children[twoCurrentIndex].children[
              threeCurrentIndex
            ].children.length > 0
          ">
          <draggable v-model="
              editMenuList[firstCurrentIndex].children[twoCurrentIndex]
                .children[threeCurrentIndex].children
            " animation="1000">
            <transition-group>
              <li :class="[
                  'menu_item',
                  fourCurrentIndex == index ? 'current_item' : '',
                ]" v-for="(item, index) in editMenuList[firstCurrentIndex]
                  .children[twoCurrentIndex].children[threeCurrentIndex]
                  .children" :key="index" @click="clickItem(item, index, 4)">
                <span>{{ item.show_name }}</span>
                <div class="right">
                  <i class="iconfont lebo-bianji" style="color: #e39f28" @click.stop.prevent="editItem(item, index, 4)"></i>
                  <i class="iconfont lebo-shanchu1" style="color: #f55265" @click.stop.prevent="delItem(index, 4)"></i>
                  <i class="el-icon-arrow-right" v-if="item.is_folder"></i>
                </div>
              </li>
            </transition-group>
          </draggable>
        </ul>
        <div class="empty_box" v-else>
          <img src="../../../assets/images/empty.png" alt="" />
          <span>请添加页面</span>
        </div>
      </li>-->
    </ul>
    <lebo-dialog
      append-to-body
      :title="isCatalogueEdit ? '编辑目录' : '添加目录'"
      :isShow="isAddCatalogueDialog"
      width="30%"
      @close="closeAddCatalogueDialog" footerSlot
    >
      <el-form
        :model="addCatalogueForm"
        ref="addCatalogueFormRefs"
        :rules="addCatalogueFormRules"
        label-width="120px"
      >
        <el-form-item label="目录名称:" prop="menu_name">
          <el-input
            :value="addCatalogueForm.menu_name"
            placeholder="请输入目录名称"
            @input="(e) => (addCatalogueForm.menu_name = validSpace(e))"
          ></el-input>
          <!-- <el-input  maxlength="15" v-model="addCatalogueForm.show_name" @input="(e) => (addCatalogueForm.show_name = validSpace(e))"></el-input> -->
        </el-form-item>
        <el-form-item label="图标:">
          <div class="add_icon_item" @click="openIconPage(addCatalogueForm.menu_icon)">
            <span
              :class="
              addCatalogueForm.menu_icon ? 'iconfont  lebo-' + addCatalogueForm.menu_icon : 'iconfont lebo-tubiaoadd'
            "
            ></span>
            <i
              class="el-icon-close"
              @click.stop.prevent="addCatalogueForm.menu_icon = ''"
              v-if="addCatalogueForm.menu_icon"
            ></i>
          </div>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <lbButton type="goBack" icon="back" @click="isAddCatalogueDialog = false">返 回</lbButton>
        <lbButton type="orign" icon="confirm" @click="fnAddCatalogue">保 存</lbButton>
      </div>
    </lebo-dialog>
    <iconDialog
      :ischecked="ischecked"
      @add="addIcon"
      @close="isIconDialog = false"
      v-if="isIconDialog"
    ></iconDialog>
    <lebo-dialog
      append-to-body
      title="添加页面"
      :isShow="isAddPageDialog"
      width="50%"
      @close="pageTableData = [];isAddPageDialog=false" footerSlot
    >
      <div class="page_content_box">
        <el-tree
          :data="adminMenuTreeList"
          :props="defaultProps"
          node-key="id"
          @node-click="handleNodeClick"
          default-expand-all
          :expand-on-click-node="false"
          :render-content="renderContent"
        ></el-tree>
        <el-table
          :data="pageTableData"
          ref="multipleTable"
          tooltip-effect="dark"
          border
          stripe
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="50" align="center" header-align="center"></el-table-column>
          <el-table-column prop="name" label="页面"></el-table-column>
        </el-table>
      </div>
      <div slot="footer" class="dialog-footer">
        <lbButton type="goBack" icon="back" @click="isAddPageDialog = false">返 回</lbButton>
        <lbButton type="orign" icon="confirm" @click="fnAddPage()">保 存</lbButton>
      </div>
    </lebo-dialog>
    <lebo-dialog
      append-to-body
      title="编辑页面"
      :isShow="isEditPageDialog"
      width="30%"
      @close="closeEditPageDialog" footerSlot
    >
      <el-form
        :model="editPageForm"
        ref="editPageFormRefs"
        :rules="addCatalogueFormRules"
        label-width="120px"
      >
        <el-form-item label="页面名称:">
          {{
          editPageForm.menu_name
          }}
        </el-form-item>
        <el-form-item label="显示名称:" prop="show_name">
          <el-input
            placeholder="请输入显示名称"
            maxlength="15"
            v-model="editPageForm.show_name"
            @input="(e) => (editPageForm.show_name = validSpace(e))"
          ></el-input>
        </el-form-item>
        <el-form-item label="图标:">
          <div class="add_icon_item" @click="openIconPage(editPageForm.menu_icon)">
            <span
              :class="
              editPageForm.menu_icon ? 'iconfont  lebo-' + editPageForm.menu_icon : 'iconfont lebo-tubiaoadd'
            "
            ></span>
            <i
              class="el-icon-close"
              @click.stop.prevent="editPageForm.menu_icon = ''"
              v-if="editPageForm.menu_icon"
            ></i>
          </div>
          <!-- <div class="add_icon_item" @click="isIconDialog = true">
          <span class="iconfont lebo-tubiaoadd"></span> </div>-->
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <lbButton type="goBack" icon="back" @click="isEditPageDialog = false">返 回</lbButton>
        <lbButton type="orign" icon="confirm" @click="fnEditPage">保 存</lbButton>
      </div>
    </lebo-dialog>
  </div>
</template>
<script>
import systemApi from '@/api/systemapi'
import { mapGetters, mapState, mapMutations, mapActions } from 'vuex'
// 单个文件引入
import draggable from 'vuedraggable'
import iconDialog from './iconDialog.vue'
export default {
  props: {
    menuList: { type: Array, deep: true }
  },
  // 注册组件
  components: {
    draggable,
    iconDialog
  },
  data () {
    return {
      ischecked: '',
      editMenuList: [],
      firstCurrentIndex: 0, // 可编辑第一级index  1
      twoCurrentIndex: 0, // 可编辑第二级index  2
      threeCurrentIndex: 0, // 可编辑第三级index  3
      fourCurrentIndex: 0, // 可编辑第四级index   4
      type: 1, // 新增第一级目录
      // 是否展示 添加目录对话框
      isAddCatalogueDialog: false,
      isCatalogueEdit: false,
      isEditIndex: 0,
      // 添加目录表单
      addCatalogueForm: {
        menu_id: '', // 菜单id,除页面和按钮外赋值为0
        menu_name: '', // 菜单名称
        show_name: '', // 页面展示名称
        menu_icon: '', // 图标
        menu_url: '', // 图标地址
        code: '', // 页面或者按钮编码
        sort_number: 1, // 排序
        is_btn: false, // 是否是按钮
        is_folder: true, // 是否是目录
        children: []
      },
      // 添加目录表单验证
      addCatalogueFormRules: {
        show_name: [
          { required: true, message: '请输入显示菜单名称', trigger: 'blur' }
        ],
        menu_name: [
          { required: true, message: '请输入目录名称', trigger: 'blur' }
        ]
      },
      // 是否展示 添加页面 对话框
      isAddPageDialog: false,
      // 添加页面表单
      addPageFrom: {},
      // 添加页面表单验证
      addPageFromRules: {},
      adminMenuTreeList: [],
      adminAllMenuTreeList: [],
      defaultProps: {
        children: 'children',
        label: 'name'
      },
      pageTableData: [],
      checkPageData: [],
      isEditPageDialog: false,
      // 修改页面
      editPageForm: {
        menu_id: '', // 菜单id,除页面和按钮外赋值为0
        menu_name: '', // 菜单名称
        show_name: '', // 页面展示名称
        menu_icon: '', // 图标
        menu_url: '', // 图标地址
        code: '', // 页面或者按钮编码
        sort_number: 1, // 排序
        is_btn: false, // 是否是按钮
        is_folder: false, // 是否是目录
        children: []
      },
      // 是否展示 图标库 对话框
      isIconDialog: false,
      queryIconForm: {
        menu_icon: '', // 图标名称
        module_type: 0 // 0=全部 1=线性，2=面性
      },
      iconLibraryList: []
    }
  },
  created () {
    // console.log('created--config--', this.menuList);
    if (this.menuList) {
      this.editMenuList = this.menuList
    }
    this.fnGetAdminMenusList()
  },
  mounted () {},
  computed: {
    ...mapState(['queryObj', 'queryStatus']),
    ...mapGetters(['isAdmin', 'getOptions', 'getWayName']),
    configList: function () {
      return this.fnSort(this.editMenuList.concat())
    }
  },
  watch: {
    menuList (val, oldVla) {
      // console.log('menuList---config--', val);
      if (val) {
        this.editMenuList = val
      }
    }
  },
  methods: {
    // 排序
    fnSort (data) {
      data.forEach((item, index) => {
        item.sort_number = index + 1
        if (item.children && item.children.length > 0) {
          this.fnSort(item.children)
        }
      })
      return data
    },
    // 获取admin菜单列表
    async fnGetAdminMenusList () {
      const res = await systemApi.getAdminMenusList()
      console.log(res)
      if (res && res.Code === 200) {
        this.adminMenuTreeList = res.Data.concat()
        this.adminAllMenuTreeList = JSON.stringify(
          this.adminMenuTreeList.concat()
        )
      } else {
        this.adminAllMenuTreeList = []
        this.adminMenuTreeList = []
      }
    },
    // 打开添加目录对话框
    openAddCatalogue (type) {
      console.log(this.addCatalogueForm)
      this.type = type
      this.isAddCatalogueDialog = true
      this.isCatalogueEdit = false
    },
    // 点击菜单
    clickItem (item, index, type) {
      this.fnsetIndex(index, type)
    },
    fnsetIndex (index, type) {
      if (type === 1) {
        this.firstCurrentIndex = index
        this.twoCurrentIndex = 0
        this.threeCurrentIndex = 0
        this.fourCurrentIndex = 0
      } else if (type === 2) {
        this.twoCurrentIndex = index
        this.threeCurrentIndex = 0
        this.fourCurrentIndex = 0
      } else if (type === 3) {
        this.threeCurrentIndex = index
        this.fourCurrentIndex = 0
      } else if (type === 4) {
        this.fourCurrentIndex = index
      }
    },
    // 编辑页面
    editItem (item, index, type) {
      // console.log(item);
      this.type = type
      this.fnsetIndex(index, type)
      this.isEditIndex = index
      if (item.is_folder) {
        this.isAddCatalogueDialog = true
        this.isCatalogueEdit = true
        this.isEditPageDialog = false
        this.addCatalogueForm = JSON.parse(JSON.stringify(item))
        this.ischecked = this.addCatalogueForm.menu_icon
      } else {
        this.isEditPageDialog = true
        this.editPageForm = JSON.parse(JSON.stringify(item))
      }
    },
    // 删除页面
    delItem (index, type) {
      if (type === 1) {
        this.editMenuList.splice(index, 1)
      } else if (type === 2) {
        this.editMenuList[this.firstCurrentIndex].children.splice(index, 1)
      } else if (type === 3) {
        this.editMenuList[this.firstCurrentIndex].children[
          this.twoCurrentIndex
        ].children.splice(index, 1)
      } else if (type === 4) {
        this.editMenuList[this.firstCurrentIndex].children[
          this.twoCurrentIndex
        ].children[this.threeCurrentIndex].children.splice(index, 1)
      }
    },
    // 验证表单
    fnAddCatalogue () {
      this.ischecked = ''
      var that = this
      this.$refs.addCatalogueFormRefs.validate(valid => {
        if (valid) {
          that.addAndEditCata()
        } else {
          return false
        }
      })
    },
    // 新增编辑目录
    addAndEditCata () {
      // console.log('addAndEditCata----');
      var item = JSON.parse(JSON.stringify(this.addCatalogueForm))
      item.menu_name = this.addCatalogueForm.menu_name
      item.show_name = this.addCatalogueForm.menu_name
      item.menu_id =
        this.addCatalogueForm.menu_id ||
        this.guid()
          .replace(/-/g, '')
          .substring(0, 24)
      if (this.type === 1) {
        if (this.isCatalogueEdit) {
          this.editMenuList[this.isEditIndex] = item
        } else {
          item.sort_number = this.editItem.length
          this.editMenuList.push(item)
        }
      } else if (this.type === 2) {
        // console.log(this.editMenuList[this.firstCurrentIndex]);
        if (this.isCatalogueEdit) {
          this.editMenuList[this.firstCurrentIndex].children[
            this.isEditIndex
          ] = item
        } else {
          item.sort_number = this.editMenuList[
            this.firstCurrentIndex
          ].children.length
          this.editMenuList[this.firstCurrentIndex].children.push(item)
        }
      } else if (this.type === 3) {
        if (this.isCatalogueEdit) {
          this.editMenuList[this.firstCurrentIndex].children[
            this.twoCurrentIndex
          ].children[this.isEditIndex] = item
        } else {
          item.sort_number = this.editMenuList[this.firstCurrentIndex].children[
            this.twoCurrentIndex
          ].children.length
          this.editMenuList[this.firstCurrentIndex].children[
            this.twoCurrentIndex
          ].children.push(item)
        }
      }
      this.isAddCatalogueDialog = false
      // console.log('addAndEditCata----', this.editMenuList);
    },
    guid () {
      function S4 () {
        return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1)
      }
      return (
        S4() +
        S4() +
        '-' +
        S4() +
        '-' +
        S4() +
        '-' +
        S4() +
        '-' +
        S4() +
        S4() +
        S4()
      )
    },
    // 关闭 添加目录对话框触发
    closeAddCatalogueDialog () {
      this.isAddCatalogueDialog = false
      // console.log('closeAddCatalogueDialog-----');
      var obj = {
        menu_id: '', // 菜单id,除页面和按钮外赋值为0
        menu_name: '', // 菜单名称
        show_name: '', // 页面展示名称
        menu_icon: '', // 图标
        menu_url: '', // 图标地址
        code: '', // 页面或者按钮编码
        sort_number: 1, // 排序
        is_btn: false, // 是否是按钮
        is_folder: true, // 是否是目录
        children: []
      }
      this.ischecked = ''
      this.$refs.addCatalogueFormRefs.resetFields()
      this.addCatalogueForm = JSON.parse(JSON.stringify(obj))
    },
    // 展示添加页面对话框
    openAddPage (type) {
      this.type = type
      this.isAddPageDialog = true
      this.isCatalogueEdit = false
    },
    // 根据number属性值判断是否渲染节点
    renderContent (h, { node, data, store }) {
      if (data.isButton || data.is_page) {
        this.remove(node, data)
        return null
      } else {
        return (
          <span class="custom-tree-node">
            <span>{data.name}</span>
          </span>
        )
      }
    },
    // 删除节点数据方法
    remove (node, data) {
      const parent = node.parent
      const children = parent.data.children || parent.data
      const index = children.findIndex(d => d.id === data.id)
      children.splice(index, 1)
    },
    // 点击树节点触发
    handleNodeClick (data) {
      var newArr = []
      this.filterTree(
        JSON.parse(this.adminAllMenuTreeList).concat(),
        data.id,
        newArr
      )
      this.pageTableData = newArr
    },
    filterTree (data, id, newArr) {
      data.forEach(item => {
        if (item.id === id) {
          item.children.forEach(t => {
            if (t.is_page) {
              newArr.push(t)
            }
          })
        } else {
          if (item.children && item.children.length > 0) {
            this.filterTree(item.children, id, newArr)
          }
        }
      })
    },
    // 当选择项发生变化时会触发该事件
    handleSelectionChange (data) {
      // console.log(data);
      this.checkPageData = data
    },
    // 添加页面保存
    fnAddPage () {
      var newArr = []
      this.checkPageData.forEach((item, i) => {
        var children = []
        if (item.children && item.children.length > 0) {
          item.children.forEach((child, j) => {
            children.push({
              menu_id: child.id, // 菜单id,除页面和按钮外赋值为0
              menu_name: child.name, // 菜单名称
              show_name: child.name, // 页面展示名称
              menu_icon: child.menuIcon.slice(4, child.menuIcon.length), // 图标
              menu_url: child.menuUrl, // 图标地址
              code: child.code, // 页面或者按钮编码
              sort_number: j + 1, // 排序
              is_btn: true, // 是否是按钮
              is_folder: false // 是否是目录
            })
          })
        }
        newArr.push({
          menu_id: item.id, // 菜单id,除页面和按钮外赋值为0
          menu_name: item.name, // 菜单名称
          show_name: item.name, // 页面展示名称
          menu_icon: item.menuIcon.slice(4, item.menuIcon.length), // 图标
          menu_url: item.menuUrl, // 图标地址
          code: item.code, // 页面或者按钮编码
          sort_number: i + 1, // 排序
          is_btn: false, // 是否是按钮
          is_folder: false, // 是否是目录
          children: children
        })
      })
      console.log('this.editMenuList---', this.editMenuList)
      console.log(this.firstCurrentIndex)
      if (this.type === 1) {
        this.editMenuList.push(...newArr)
      } else if (this.type === 2) {
        this.editMenuList[this.firstCurrentIndex].children.push(...newArr)
      } else if (this.type === 3) {
        this.editMenuList[this.firstCurrentIndex].children[
          this.twoCurrentIndex
        ].children.push(...newArr)
      } else if (this.type === 4) {
        this.editMenuList[this.firstCurrentIndex].children[
          this.twoCurrentIndex
        ].children[this.threeCurrentIndex].children.push(...newArr)
      }
      this.isAddPageDialog = false
    },
    // 修改页面
    fnEditPage () {
      var that = this
      console.log('this.editPageForm----', this.editPageForm)
      this.$refs.editPageFormRefs.validate(valid => {
        if (valid) {
          var item = JSON.parse(JSON.stringify(this.editPageForm))
          // item.show_name = this.editPageForm.show_name;
          console.log('fnEditPage----', item)
          if (this.type === 1) {
            this.editMenuList[this.firstCurrentIndex] = item
            console.log('firstCurrentIndex---', this.firstCurrentIndex)
            console.log(this.editMenuList[this.firstCurrentIndex])
          } else if (this.type === 2) {
            this.editMenuList[this.firstCurrentIndex].children[
              this.isEditIndex
            ] = item
          } else if (this.type === 3) {
            this.editMenuList[this.firstCurrentIndex].children[
              this.twoCurrentIndex
            ].children[this.isEditIndex] = item
          } else if (this.type === 4) {
            this.editMenuList[this.firstCurrentIndex].children[
              this.twoCurrentIndex
            ].children[this.threeCurrentIndex].children[
              this.isEditIndex
            ] = item
          }
          this.isEditPageDialog = false
        } else {
          return false
        }
      })
    },
    closeEditPageDialog () {
      var obj = {
        menu_id: '', // 菜单id,除页面和按钮外赋值为0
        menu_name: '', // 菜单名称
        show_name: '', // 页面展示名称
        menu_icon: '', // 图标
        menu_url: '', // 图标地址
        code: '', // 页面或者按钮编码
        sort_number: 1, // 排序
        is_btn: false, // 是否是按钮
        is_folder: false, // 是否是目录
        children: []
      }
      this.$refs.editPageFormRefs.resetFields()
      this.ischecked = ''
      this.editPageForm = JSON.parse(JSON.stringify(obj))
      this.isEditPageDialog = false
    },
    openIconPage (icon) {
      this.isIconDialog = true
      this.ischecked = icon
    },
    // 添加图标
    addIcon (icon) {
      this.isIconDialog = false
      if (this.isAddCatalogueDialog) {
        this.addCatalogueForm.menu_icon = icon
      }
      if (this.isEditPageDialog) {
        this.editPageForm.menu_icon = icon
      }
    }
  }
}
</script>
<style scoped lang="less">
.menu_config_box {
  width: 100%;
  height: 550px;
  background: #ffffff;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
  border-radius: 5px 5px 5px 5px;
  display: flex;
  justify-content: flex-start;
  padding-right: 15px;
  box-sizing: border-box;

  .menu_box {
    width: 33%;
    padding-left: 20px;
    box-sizing: border-box;

    .sub_menu_box {
      height: 64px;
      border-bottom: 1px solid #e6e6e6;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .sub_title {
        font-size: 15px;
        font-weight: 700;
        color: #666666;
      }

      .right_btn_box {
        display: flex;

        .btn_box {
          width: 80px;
          height: 30px;
          font-size: 12px;
          background: #ffffff;
          border-radius: 5px 5px 5px 5px;
          border: 1px solid #01a6fb;
          color: #01a6fb;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          margin-right: 5px;

          &:hover {
            background: #01a6fb;
            border: 1px solid #01a6fb;
            color: #fff;
          }
        }

        .btn_box2 {
          width: 80px;
          height: 30px;
          font-size: 12px;
          background: #ffffff;
          border-radius: 5px 5px 5px 5px;
          border: 1px solid #1acd90;
          color: #1acd90;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;

          &:hover {
            background: #1acd90;
            border: 1px solid #1acd90;
            color: #fff;
          }
        }
      }
    }

    .item_box {
      height: calc(100% - 65px);
      overflow-y: auto;
    }

    .menu_item {
      cursor: pointer;
      height: 50px;
      padding: 0 5px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 14px;
      color: #666666;

      .left {
        .iconfont {
          display: inline-block;
          width: 16px;
          height: 16PX;
          margin-right: 5px;
        }
      }

      .right {
        .iconfont {
          display: none;
        }
      }

      &:hover {
        .right .iconfont {
          display: inline-block;
          margin-right: 10px;
        }
      }
    }

    .current_item {
      color: #01a6fb;
    }
  }

  /deep/ .empty_box {
    height: calc(100% - 65px);
    // background: #fafafa;
    font-size: 16px;
    color: #cccccc;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    img {
      width: 100px;
    }
  }
}

.page_content_box {
  display: flex;
  height: 500px;

  .el-tree {
    width: 35%;
    height: 100%;
    overflow: auto;
  }
}

.add_icon_item {
  width: 60px;
  height: 60px;
  background: #ffffff;
  border: 1px solid #f2f2f2;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  .iconfont {
    font-size: 30px;
  }
  .el-icon-close {
    position: absolute;
    top: -10px;
    right: -10px;
    width: 20px;
    height: 20px;
    font-weight: 700;
    background: rgba(0, 0, 0, 0.5);
    color: #fff;
    border-radius: 50%;
    text-align: center;
    line-height: 20px;
  }
}
</style>
