<template>
  <lebo-dialog append-to-body title="图标库" :isShow="isIconDialog" width="60%"
    class="icon_dialog" @close="closeHandle" footerSlot>
    <div class="query_box">
      <el-input placeholder="请输入icon名称" size="medium" @keyup.enter.native="fnGetIconLibraryList"
        v-model="queryIconForm.menu_icon" >
        <i slot="suffix" class="el-icon-search" @click="fnGetIconLibraryList"></i>
        <!-- <el-button slot="append" icon="el-icon-search" @click="fnGetIconLibraryList" class="iconButton"></el-button> -->
      </el-input>
      <ul class="right_box">
        <li :class="[
          'btn_item',
          queryIconForm.module_type == 0 ? 'current' : '',
        ]" @click="cliclModuleType(0)">
          全部
        </li>
        <li :class="[
          'btn_item',
          queryIconForm.module_type == item.value ? 'current' : '',
        ]" @click="cliclModuleType(item.value)" v-for="item in getOptions('IconModuleType')" :key="item.value">
          {{ item.name }}
        </li>
      </ul>
    </div>
    <ul class="icon_box" v-if="iconLibraryList.length > 0">
      <li v-for="(item, index) in iconLibraryList" :key="index"
        :class="['icon_item', item.menu_icon == ischeckedChild ? 'current' : '']" @click="checked(item.menu_icon)">
        <div :class="'iconfont lebo-' + item.menu_icon"></div>
        <div class="iconName">{{ item.menu_name }}</div>
      </li>
    </ul>
    <div class="empty_box" v-else>
      <img src="@/assets/images/empty.png" alt="" />
      <span>暂无数据</span>
    </div>
    <!-- <div slot="footer" class="dialog-footer">
      <el-button @click="isIconDialog = false">取 消</el-button>
      <el-button type="primary" @click="addIcon">保 存</el-button>
    </div> -->
  </lebo-dialog>
</template>
<script>
import systemApi from '@/api/systemapi'
import { mapGetters, mapState, mapMutations, mapActions } from 'vuex'
export default {
  props: ['ischecked'],
  data () {
    return {
      ischeckedChild: '',
      // 是否展示 图标库 对话框
      isIconDialog: true,
      queryIconForm: {
        menu_icon: '', // 图标名称
        module_type: 0 // 0=全部 1=线性，2=面性
      },
      iconLibraryList: []
    }
  },
  created () {
    this.ischeckedChild = this.ischecked
    this.fnGetIconLibraryList()
  },
  mounted () { },
  computed: {
    ...mapState(['queryObj', 'queryStatus']),
    ...mapGetters(['isAdmin', 'getOptions', 'getWayName'])
  },
  watch: {},
  methods: {
    checked (menu_icon) {
      this.ischeckedChild = menu_icon
      this.addIcon()
    },
    closeHandle () {
      this.isIconDialog = false
      this.$emit('close')
    },
    // 根据类型查询字段图标
    cliclModuleType (type) {
      this.queryIconForm.module_type = type
      this.fnGetIconLibraryList()
    },
    async fnGetIconLibraryList () {
      const res = await systemApi.getIconLibraryList(this.queryIconForm)
      this.iconLibraryList = res && res.Code === 200 ? res.Data : []
    },
    addIcon () {
      this.isIconDialog = false
      this.$emit('add', this.ischeckedChild)
    }
  }
}
</script>
<style scoped lang="less">
.icon_dialog {
  /deep/ .el-dialog__body {
    padding: 15px;
  }

  /deep/ .query_box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .iconButton{
      box-sizing: border-box;
      background-color:#01a6fb ;
      border: 1px;
      // color: #01a6fb;
      box-sizing: border-box;
      color: white;
    }
    .el-input {
      width: 30%;
    }

    .right_box {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .btn_item {
        width: 90px;
        height: 36px;
        background: #e6e6e6;
        border-radius: 18px;
        color: #999999;
        line-height: 36px;
        text-align: center;
        margin-left: 10px;
        cursor: pointer;
      }

      .current {
        background: #01a6fb;
        color: #fff;

      }
    }
  }

  /deep/ .icon_box {
    max-height: 500px;
    overflow: auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 15px;

    .icon_item {
      margin: 10px;
      text-align: center;
      font-size: 12px;
      color: #999999;
      cursor: pointer;
      .iconName{
        width: 100px;
      }
      .iconfont {
        width: 100px;
        height: 100px;
        text-align: center;
        line-height: 100px;
        background: #ffffff;
        border: 1px solid #f2f2f2;
        border-radius: 5px;
        font-size: 36px;
        margin-bottom: 5px;
        color: #666;
      }
    }

    .current {
      border: 1px;
      color: #01a6fb;

      .iconfont {
        border: 1px solid #01a6fb;
        color: #01a6fb;
      }
    }
  }

  /deep/ .empty_box {
    width: 100%;
    height: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: #cccccc;

    img {
      width: 100px;
      margin-bottom: 10px;
    }
  }
}
/deep/.el-input__icon .el-input__validateIcon {
      display: none!important;
    }
      /deep/ .el-input__validateIcon {
        display: none!important;
      }
/deep/ .el-input__suffix{
  display: flex;
  align-items: center;
}
</style>
